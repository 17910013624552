@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Lato:wght@100;300;400;700;900&display=swap");

* {
  padding: 0px;
  margin: 0px;
}
body {
  font-family: "Lato", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "Poppins", sans-serif;
}
a,
.btn,
button {
  outline: 0;
  transition: all 300ms linear 0s;
  text-decoration: none;
}
a:before,
a:after,
.btn:before,
.btn:after,
button:before,
button:after {
  transition: all 300ms linear 0s;
}
a:focus,
a:hover,
.btn:focus,
.btn:hover,
button:focus,
button:hover {
  outline: 0;
  text-decoration: none !important;
  transition: all 300ms linear 0s;
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}
img {
  width: 100%;
}
section {
  padding: 60px 0;
}
.pad-0 {
  padding: 0px !important;
}
.margintop-165 {
  margin-top: 165px;
}
.btn-dft {
  display: block;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  background: transparent;
}
.btn-dft i {
  background: #fff;
  padding: 12px;
  border-radius: 50%;
  color: #232323;
  margin-right: 10px;
  font-family: "FontAwesome";
}

@media (min-width: 1200px) {
  .container {
    max-width: 1280px;
  }
}
main {
  margin-top: 165px;
  background: #f2f3f5;
}
/* Site Header */
header {
  background: #274888;
  color: #fff;
}

header .top-header {
  border-bottom: 1px solid #24aeb1;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  background: #24aeb1;
  top: 0px;
}

header .top-header ul {
  display: flex;
  list-style: none;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
}

header .top-header ul li a span {
  font-size: 0;
}
header .top-header ul li a {
  text-decoration: none;
}
header .top-header ul li a i {
  font-size: 15px;
  color: #fff;
  font-family: "FontAwesome";
  font-weight: 100;
}
header .top-header ul li:hover a i {
  color: #274888;
}
header .top-header ul li {
  padding-right: 10px;
}

header .top-header p {
  margin: 0;
}

header .main-header {
  padding: 15px 0;
  position: fixed;
  width: 100%;
  background: #ffffff;
  top: 45px;
  transition: 0.5s all ease-in-out;
  -webkit-transition: 0.5s all ease-in-out;
  z-index: 999;
}
header .main-header.sticky {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  box-shadow: 0 0 20px 0 rgb(21 47 95 / 20%);
  transition: 0.5s all ease-in-out;
  -webkit-transition: 0.5s all ease-in-out;
}
header .main-header .col-lg-2 img {
  margin-top: 15px;
}

.search-box {
  position: relative;
}
.search-box .form-control {
  width: 100%;
  display: block;
  font-size: 14px;
  letter-spacing: 0;
  padding: 0.64rem 1rem;
  border-radius: 25px;
  background-color: #fff;
  -ms-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  height: 46px;
  margin: 0 !important;
}
.search-box button.btn {
  background: #24aeb1;
  padding: 2px 30px;
  margin: 1px;
  position: absolute;
  top: 3px;
  right: 3px;
  height: 40px;
  border-radius: 25px;
  border: none;
}
.bookNowBtn{

  margin-top: 10px !important;
  background: #24aeb1;
  padding: 2px 30px;
  margin: 1px;
  height: 40px;
  color: #fff;
  width: 134px;

  border-radius: 25px;
  border: none;

}
.bookNowBtn:hover{
  background-color:#24aeb1;
}
.search-box button.btn i {
  color: #fff;
}
nav.navbar {
  text-align: center;
  margin: 0;
  margin-top: 20px;
  padding: 0;
}

nav.navbar ul {
  list-style: none;
}

nav.navbar ul li.nav-item {
  padding: 0 15px;
}

nav.navbar ul li.nav-item a.nav-link {
  color: #202020;
  padding: 0;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
nav.navbar ul li.nav-item.dropdown:hover .dropdown-menu {
  pointer-events: auto;
  opacity: 1;
  visibility: visible;
  display: block;
  position: absolute;
}
.navbar-nav {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.site-header-account {
  position: relative;
  vertical-align: middle;
}
.site-header-account > a {
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
  -ms-transition: none;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
  color: #202020;
}
.site-header-account .account-dropdown {
  text-align: left;
  right: 0;
  top: 100%;
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: 100;
  visibility: hidden;
  color: #2d2d2d;
  padding-top: 15px;
  -ms-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  -moz-transition: all 0.35s ease;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.site-header-account:hover .account-dropdown {
  pointer-events: auto;
  opacity: 1;
  visibility: visible;
}
.site-header-account .account-dropdown .account-wrap {
  position: relative;
  background: #fff;
  box-shadow: 0 0 7px 0 rgb(0 0 0 / 3%);
  border-radius: 6px;
  border-top: 3px solid;
  border-top-color: #24aeb1;
}
.site-header-account .account-dropdown .account-wrap:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -15px;
  right: var(--right, 15%);
  border-left-width: 6px;
  border-color: #24aeb1;
  border-left-color: transparent;
  border-right-width: 6px;
  border-right-color: transparent;
  border-bottom-width: 6px;
  border-top-width: 0;
  border-style: solid;
  transform: translateX(-50%) translateY(100%);
  -ms-transition: all 0.35s;
  -o-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}
.site-header-account .account-dropdown .account-inner {
  width: 320px;
  padding: 20px;
  max-height: 500px;
  overflow: auto;
}
.login-form-head {
  border-bottom: 1px solid;
}
.site-header-account .login-form-title {
  font-size: 18px;
  color: #2d2d2d;
  font-weight: 600;
}
.site-header-account .account-dropdown p {
  margin-bottom: 15px;
}
.site-header-account .account-dropdown label {
  margin-bottom: 0.5rem;
  font-size: 14px;
  line-height: 1.5;
  display: flex;
}
.site-header-account .account-dropdown input {
  line-height: normal !important;
  border-width: 1px;
  padding: 15px;
  height: 40px;
  border: 1px solid #ababab;
  width: 100%;
  border-radius: 5px;
}
.site-header-account .account-dropdown .verification-otp input {
  margin: 0 2px;
  text-align: center;
  line-height: 80px;
  font-size: 14px;
  border: solid 1px rgb(36 174 177 / 40%);
  box-shadow: 0 0 5px rgb(36 174 177 / 40%) inset;
  outline: none;
  width: 13% !important;
  transition: all 0.2s ease-in-out;
  border-radius: 3px;
  padding: 10px;
}
a.lostpass-link {
  font-size: 14px;
  color: #24aeb1;
  margin-top: 15px !important;
}
.login-form-bottom h4 {
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 5px;
}
.login-form-bottom a.lostpass-link {
  margin-top: 0px !important;
}
a.register-link {
  font-size: 14px;
  color: #24aeb1;
}
.required {
  border-bottom: 0 !important;
  color: red;
}
.btn.btn-primary {
  background-color: #24aeb1;
  border-color: #24aeb1;
  color: #fff;
}

.account {
  display: inline-block;
}

.product-head {
  display: inline-block;
  margin-left: 20px;
}

.site-header-wishlist {
  display: inline-block;
}
.cart-woocommerce {
  display: inline-block;
  margin-left: 10px;
}

.cart-woocommerce i {
  font-size: 20px;
  font-family: "FontAwesome";
  padding-right: 10px;
  color: #23abaf;
}
.product-head a.opal-header-wishlist.header-button i {
  color: #24adb1;
  font-size: 22px;
}
.cart-woocommerce a.cart-contents.header-button {
  color: #202020;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
header .col-lg-3.text-right {
  padding-top: 15px;
}

button.navbar-toggler {
  display: none;
}
/* Site Header */

/* Home Banner */
#home-banner .home-banner-content {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
}

#home-banner .item {
  position: relative;
  min-height: 500px;
}
#home-banner.owl-carousel .owl-item .banner-bg {
  width: auto;
}
#home-banner .home-banner-content h2 {
  font-size: 54px;
  color: #fff;
}

#home-banner .home-banner-content .row i {
  font-family: "FontAwesome";
  font-size: 20px;
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
}

#home-banner .home-banner-content h4 {
  font-size: 18px;
  color: #fff;
  margin-left: 15px;
}
#home-banner .home-banner-content a.banner-cta {
  margin-top: 40px;
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  padding: 0 20px 0 0;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  background-image: linear-gradient(to right,#ffffff 0,#ffffff 100%);
  position: relative;
  z-index: 1;
  border-radius: 30px;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  -moz-transition: all .3s;
  -webkit-transition: all .3s;
  transition: all .3s;
  background-repeat: no-repeat;
  background-size: 0 100%;
  width: 40%;
}
#home-banner .home-banner-content a.banner-cta:hover {
  color: #24aeb1;
  background-size: 100% 100%;
}
#home-banner .home-banner-content a.banner-cta i {
  background: #fff;
  padding: 15px;
  border-radius: 50%;
  color: #232323;
  margin-right: 15px;
  width: 50px;
  height: 50px;
  line-height: 24px;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  -moz-transition: all .3s;
  -webkit-transition: all .3s;
  transition: all .3s;
}
#home-banner .home-banner-content a.banner-cta:hover i {text-indent: 15px;}
#home-banner .owl-dots {
  display: none;
}
#home-banner .owl-nav {
  position: absolute;
  width: 100%;
  top: 42%;
}
#home-banner .owl-nav button.owl-prev span {
  background: #fff;
  position: absolute;
  left: 30px;
  width: 40px;
  height: 40px;
  line-height: 38px;
  font-size: 26px;
  font-weight: 600;
  border-radius: 50%;
}
#home-banner .owl-nav button.owl-next span {
  background: #fff;
  position: absolute;
  right: 30px;
  width: 40px;
  height: 40px;
  line-height: 38px;
  font-size: 26px;
  font-weight: 600;
  border-radius: 50%;
}
#home-banner .owl-nav button:hover span {
  color: #274888;
}

#home-banner .react-multiple-carousel__arrow--left {
  left: calc(2% + 1px);
}
#home-banner .react-multiple-carousel__arrow--right {
  right: calc(2% + 1px);
}
#home-banner .react-multiple-carousel__arrow {
  background: rgb(255 255 255);
}
#home-banner .react-multiple-carousel__arrow::before {
  font-size: 18px;
    color: #1c42a9;
    font-weight: 700;
    line-height: 28px;
}

.main-banner {
  position: relative;
  padding: 0 5%;
}
/* Home Banner End */

/* Feature Wrap */
.feature-wrap ul {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
}

.feature-wrap {
  /*position: absolute;
    width: 1250px;
    bottom: -35px;
    left: 0;
    right: 0;*/
  z-index: 99;
  padding: 20px 0;
  /*background: #fff;*/
  margin: 0 auto;
  border-radius: 10px;
  /*box-shadow: 0px 0px 13px 0px #0000003d;*/
}

.feature-wrap ul li {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.feature-wrap ul li i {
  font-family: "FontAwesome";
  background: #41cdcf;
  border-radius: 50%;
  width: 60px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #fff;
}

.feature-wrap ul li h4 {
  font-size: 15px;
  padding: 0 15px;
  line-height: 19px;
  font-weight: 600;
}
.feature-wrap ul li h4 span {
  float: left;
  font-size: 14px;
  font-weight: normal;
}
.feature-wrap ul li:nth-child(2) i {
  background: #f54f9a;
}
.feature-wrap ul li:nth-child(3) i {
  background: #83c847;
}
.feature-wrap ul li:nth-child(4) i {
  background: #51acf6;
}
.feature-wrap ul li:nth-child(5) i {
  background: #ffb61b;
}
/* Feature Wrap End */

.offers-section {
  padding: 50px 0 30px;
  background: #f2f3f5;
}

.offers-section .offers-section-inner {
  background: url(./images/banner-h3-01.jpg) no-repeat;
  padding: 30px;
  min-height: 360px;
  background-size: cover;
  border-radius: 10px;
  background-position: center;
}
.offers-section .offers-section-inner h3 {
  font-size: 30px;
  color: #fff;
  margin-bottom: 20px;
  margin-top: 20px;
}

.offers-section .offers-section-inner .cta__content-item {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 40px;
}
.offers-section .offers-section-inner-2 {
  background: url(./images/h1-banner02.jpg) no-repeat;
  padding: 30px;
  min-height: 165px;
  background-size: cover;
  border-radius: 10px;
  background-position: center;
  text-align: right;
  margin-bottom: 30px;
}
.offers-section .offers-section-inner-3 {
  background: url(./images/h1-banner03.jpg) no-repeat;
  padding: 30px;
  min-height: 165px;
  background-size: cover;
  border-radius: 10px;
  background-position: center;
}
.offers-section .offers-section-inner-2 h3,
.offers-section .offers-section-inner-3 h3 {
  color: #fff;
  font-size: 26px;
  line-height: 28px;
  margin-bottom: 10px;
}
.offers-section .offers-section-inner .btn-dft {
  padding: 0 20px 0 0;
  transition: all .3s ease;
  background-image: linear-gradient(to right,#ffffff 0,#ffffff 100%);
  position: relative;
  z-index: 1;
  border-radius: 30px;
  transition: all .3s;
  background-repeat: no-repeat;
  background-size: 0 100%;
  width: 52%;
}
.offers-section .offers-section-inner .btn-dft:hover {
  color: #24aeb1;
  background-size: 100% 100%;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.50);
}
.offers-section .offers-section-inner-2 .btn-dft:hover, .offers-section .offers-section-inner-3 .btn-dft:hover {color: #fff;}
.feature-brand {
  padding: 30px 0;
}
.feature-brand .feature-brand-bg {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}
.feature-brand h3 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 30px;
}
.feature-brand .owl-carousel {
  position: relative;
}
.feature-brand .owl-carousel .item {
  padding: 10px 30px;
  background: #ffff;
  border-radius: 10px;
}
.feature-brand .owl-carousel .owl-dots {
  display: none;
}
.feature-brand .owl-carousel .owl-nav {
  position: absolute;
  top: -80px;
  right: 0;
}
.feature-brand .owl-carousel .owl-nav button {
  background: #f2f3f5;
  width: 40px;
  height: 40px;
  line-height: 38px;
  font-size: 26px;
  font-weight: 600;
  border-radius: 50%;
}
.feature-brand .owl-carousel .owl-nav button:hover {
  background: #24aeb1;
}

/* Product Slider */
.health-product {
  padding: 30px 0;
}
.health-product .health-product-bg,
.popular-categories .popular-categories-bg,
.home-blog .home-blog-bg {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}
.health-product h3 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 30px;
}
.health-product .owl-carousel {
  position: relative;
}
.health-product .owl-carousel .owl-dots {
  display: none;
}
.health-product .owl-carousel .owl-nav {
  position: absolute;
  top: -80px;
  right: 0;
}
.health-product .owl-carousel .owl-nav button {
  background: #f2f3f5;
  width: 40px;
  height: 40px;
  line-height: 38px;
  font-size: 26px;
  font-weight: 600;
  border-radius: 50%;
}
.health-product .owl-carousel .owl-nav button:hover {
  background: #24aeb1;
}

#health-slider .item {
  margin: 5px;
}
.health-slider{
  display: flex;
  justify-content: center;
}
.product-block {
  position: relative;
  background-color: #fff;
  transition: all ease-out 0.3s;
  display: flex;
  border-radius: 6px;
  flex-direction: column;
  margin-bottom: 0;
  height: 100%;
  overflow: hidden;
  width: 200px;
  height: 420px;
}
.product-block:hover {
  box-shadow: 0 3px 13px rgb(0 0 0 / 11%);
}
.product-transition {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}
.product-img-wrap {
  overflow: hidden;
  position: relative;
}
.product-img-wrap .inner {
  width: 100%;
  height: 100%;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
.product-img-wrap.top-to-bottom .product-image {
  bottom: 100%;
  border-radius: 5px;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.product-block:hover .product-image img {
  transform: scale(1.1);
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.product-img-wrap.top-to-bottom .product-image {
  bottom: 100%;
  opacity: 1;
  -ms-opacity: 1;
  -o-opacity: 1;
  -moz-opacity: 1;
  -webkit-opacity: 1;
  filter: alpha(opacity=100);
  -ms-transition: opacity 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: opacity 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -moz-transition: opacity 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: opacity 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: opacity 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.products li img,
ul.products li.product img {
  display: block;
  margin: 0 auto;
  width: 100%;
}
.product-img-wrap .product-image.second-image {
  position: absolute;
  width: 100%;
  height: 100%;
}
.product-img-wrap .product-image {
  opacity: 1;
  -ms-opacity: 1;
  -o-opacity: 1;
  -moz-opacity: 1;
  -webkit-opacity: 1;
  filter: alpha(opacity=100);
  -ms-transition: opacity 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: opacity 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -moz-transition: opacity 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: opacity 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: opacity 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.group-label {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
}
.group-label > span {
  border-radius: 4px;
  font-size: 11px;
  display: inline-block;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
  padding: 0 8px;
  text-transform: uppercase;
  background-color: #84be52;
}
.group-label > span span {
  display: block;
}
.group-label .instock {
  display: none;
  background-color: #86d03b;
}
.group-label .outofstock {
  background-color: #d8d8d8;
  color: #aaa;
}
.group-label .onbackorder {
  background-color: #ffc107;
}
.group-label .new-label {
  background-color: #000;
}
.caption {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
}
.caption .posted_in {
  font-size: 13px;
}
.caption .posted_in a {
  color: #7d879c;
  font-family: "Poppins", sans-serif;
}
.caption .posted_in a:hover {
  color: #1c8687;
}

.caption .product__title {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  margin: 0 0 5px;
  padding-top: 5px;
  text-transform: capitalize;
  cursor: pointer;
}
.caption .product__title a {
  color: #1d2a38;
}
.caption .price {
  font-size: 16px;
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
}
.caption .price del,
.caption .price del {
  text-decoration: line-through;
  position: relative;
}
.caption .price ins,
.caption .price ins {
  font-weight: 700;
  font-size: 18px;
  color: #eb3a7b;
  text-decoration: none;
}
.price del {
  font-weight: 400;
  text-decoration: line-through;
  position: relative;
  margin-left: 0.327em;
}
.product-footer {
  overflow: hidden;
  margin-top: 15px;
}
.product-footer .shop-action {
  transition: all 0.35s ease;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
 
}
.product-footer .shop-action span {
  font-size: 14px;
  margin-right: auto;
  align-items: center;
  font-weight: 600;
  white-space: nowrap;
  text-transform: uppercase;
  width: auto;
  height: auto;
  display: flex;
  padding: 0 20px 0 0;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-image: linear-gradient(to right, #24aeb1 0, #24aeb1 100%);
  position: relative;
  z-index: 1;
  border-radius: 30px;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-repeat: no-repeat;
  background-size: 0 100%;
  color: #1d2a38;
}
.product-footer .shop-action span:before {
  content: "\f291";
  width: 40px;
  height: 40px;
  border-radius: 40px;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 5px;
  z-index: 1;
  background-color: #24aeb1;
  font-family: "FontAwesome";
  font-weight: normal;
}
.product-footer .shop-action span:hover {
  color: #fff;
  background-size: 100% 100%;
}
.product-footer .shop-action span:hover:before {
  text-indent: 15px;
}

.star-rating {
  /*border: solid 1px #ccc;*/
  display: flex;
  flex-direction: row-reverse;
  font-size: 1.5em;
  justify-content: space-around;
  /*padding: 0 0.2em;*/
  text-align: center;
  width: 5em;
  margin-bottom: 5px;
}
.star-rating input {
  display: none;
}
.star-rating label {
  color: #ccc;
  cursor: pointer;
  line-height: 22px;
}
.star-rating :checked ~ label {
  color: #f90;
}
.star-rating label:hover,
.star-rating label:hover ~ label {
  color: #fc0;
}
.product-transition .group-action {
  z-index: 3;
  transition: all 0.3s ease-in-out;
  width: 2rem;
  position: absolute;
  top: 20px;
  right: 20px;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.add-wishlist-button {
  position: relative;
}
.add-wishlist-button a {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
  white-space: nowrap;
  font-size: 0px;
  margin-bottom: 5px;
  background-color: #f3f5f9;
  border: 1px solid #f3f5f9;
  display: block;
}
.add-wishlist-button a:before {
  content: "\f004";
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "FontAwesome";
  color: #333;
}

.compare-button,
.quick-view-button {
  visibility: hidden;
  opacity: 0;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.product-block:hover .compare-button,
.product-block:hover .quick-view-button {
  visibility: visible;
  opacity: 1;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.compare-button a,
.quick-view-button a {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
  white-space: nowrap;
  font-size: 0px;
  margin-bottom: 5px;
  background-color: #f3f5f9;
  border: 1px solid #f3f5f9;
  display: block;
}
.compare-button a:before {
  content: "\f074";
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "FontAwesome";
  color: #333;
}
.quick-view-button a:before {
  content: "\f06e";
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "FontAwesome";
  color: #333;
}

.product-block:hover .product-img-wrap .inner {
  -ms-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -o-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -moz-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -webkit-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
product-block:hover .product-img-wrap.top-to-bottom .inner {
  -ms-transform: translate3d(0, 100%, 0);
  -o-transform: translate3d(0, 100%, 0);
  -moz-transform: translate3d(0, 100%, 0);
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

/* Deal Of the Day */
.deal-the-day {
  background: #f2f3f5;
}
.deal-the-day h3 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 30px;
}
.deal-the-day .owl-carousel {
  position: relative;
}
.deal-the-day .owl-carousel .owl-dots {
  display: none;
}
.deal-the-day .owl-carousel .owl-nav {
  position: absolute;
  top: -80px;
  right: 0;
}
.deal-the-day .owl-carousel .owl-nav button {
  background: #fff;
  width: 40px;
  height: 40px;
  line-height: 38px;
  font-size: 26px;
  font-weight: 600;
  border-radius: 50%;
}
.deal-the-day .owl-carousel .owl-nav button:hover {
  background: #24aeb1;
}

#dealday-slider .item {
  margin: 5px;
}
.deal-the-day .product-block {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

/* Popular Categories */
.popular-categories {
  background: #f2f3f5;
  padding: 30px 0 0;
}
.popular-categories h3 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 30px;
}
.popular-categories .owl-carousel {
  position: relative;
}
.popular-categories .owl-carousel .owl-dots {
  display: none;
}
.popular-categories .owl-carousel .owl-nav {
  position: absolute;
  top: -80px;
  right: 0;
}
.popular-categories .owl-carousel .owl-nav button {
  background: #f2f3f5;
  width: 40px;
  height: 40px;
  line-height: 38px;
  font-size: 26px;
  font-weight: 600;
  border-radius: 50%;
}
.popular-categories .owl-carousel .owl-nav button:hover {
  background: #24aeb1;
}
#popular-categories-slider .popular-categories-inner {
  text-align: center;
  color: #333;
  cursor: pointer;
}
#popular-categories-slider .popular-categories-inner img {
  margin: 0 auto 15px;
  border-radius: 10px;
}
#popular-categories-slider .popular-categories-inner h4 {
  font-size: 18px;
  text-transform: capitalize;
}
#popular-categories-slider .popular-categories-inner:hover h4 {
  color: #24aeb1;
}


/* Blog */
.home-blog h3 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 30px;
}
.home-blog .owl-carousel {
  position: relative;
}
.home-blog .owl-carousel .owl-dots {
  display: none;
}
.home-blog .owl-carousel .owl-nav {
  position: absolute;
  top: -80px;
  right: 0;
}
.home-blog .owl-carousel .owl-nav button {
  background: #f2f3f5;
  width: 40px;
  height: 40px;
  line-height: 38px;
  font-size: 26px;
  font-weight: 600;
  border-radius: 50%;
}
.home-blog .owl-carousel .owl-nav button:hover {
  background: #24aeb1;
}

.home-blog .owl-carousel .post-inner .post-thumbnail {
  overflow: hidden;
  border-radius: 10px;
  display: block;
}
.home-blog .owl-carousel .post-inner .post-thumbnail a img {
  -ms-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -o-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -moz-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -webkit-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
.home-blog .owl-carousel .post-inner:hover .post-thumbnail a img {
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  -ms-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -o-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -moz-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -webkit-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}

.home-blog .owl-carousel .post-inner .post-content .entry-title {
  font-size: 18px;
  font-weight: 500;
  padding-top: 0;
  margin-bottom: 25px;
  position: relative;
  flex: 0 0 100%;
}
.home-blog .owl-carousel .post-inner .post-content .entry-title a {
  color: #1d2a38;
}
.home-blog .owl-carousel .post-inner:hover .post-content .entry-title a {
  color: #24aeb1;
}
.home-blog .owl-carousel .post-inner .post-content .entry-meta {
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  color: #aaa;
  position: relative;
  margin-bottom: 0.95rem;
}

.home-blog .owl-carousel .post-inner .post-content {
  padding-top: 20px;
}
.home-blog .owl-carousel .post-inner .post-content .entry-meta a {
  color: #24aeb1;
}

.home-blog .owl-carousel .post-inner .post-content .entry-content a {
  font-size: 13px;
  margin-right: auto;
  align-items: center;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  width: 52%;
  height: auto;
  display: flex;
  padding: 0 20px 0 0;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-image: linear-gradient(to right, #24aeb1 0, #24aeb1 100%);
  position: relative;
  z-index: 1;
  border-radius: 30px;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-repeat: no-repeat;
  background-size: 0 100%;
  color: #1d2a38;
}
.home-blog .owl-carousel .post-inner .post-content .entry-content a:before {
  content: "\f291";
  width: 40px;
  height: 40px;
  border-radius: 40px;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 5px;
  z-index: 1;
  background-color: #24aeb1;
  font-family: "FontAwesome";
  font-weight: normal;
}
.home-blog .owl-carousel .post-inner .post-content .entry-content a:hover {
  color: #fff;
  background-size: 100% 100%;
}
.home-blog
  .owl-carousel
  .post-inner
  .post-content
  .entry-content
  a:hover:before {
  text-indent: 15px;
}

/* Footer */
.newsletter {
  background: url(./images/bgf-newsletter.png) bottom center no-repeat #f2f3f5;
  background-size: contain;
  padding: 90px 0;
}
.newsletter h4 {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 25px;
}

.newsletter p {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto 25px;
}

.newsletter form input {
  width: 100%;
  height: 45px;
  padding-left: 20px;
  margin: 0;
  border-radius: 30px;
  border: 2px solid #e6e6e6;
}

.newsletter form input[type="submit"] {
  width: 13%;
  padding: 0;
  position: absolute;
  top: 4px;
  right: 4px;
  height: 37px;
  border: none;
  background: #24aeb1;
  color: #fff;
}

.newsletter form {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  margin: 0 auto;
}
footer .footer-bg {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}
.footer-info {
  padding: 40px 0;
}

.footer-info i {
  display: inline-block;
  font-family: "FontAwesome";
  background: #fff;
  box-shadow: 0px 0px 6px 0px #00000040;
  padding: 15px;
  border-radius: 50%;
  color: #24aeb1;
  font-size: 30px;
  margin-right: 18px;
  vertical-align: top;
}

.footer-info .info-details {
  display: inline-block;
  width: 75%;
}

.footer-info .info-details h4 {
  font-size: 20px;
  margin-bottom: 12px;
}

.footer-info .info-details p {
  font-size: 16px;
  margin-bottom: 0px;
}

.footer-info .info-details h5 a {
  font-size: 24px;
  color: #24aeb1;
  margin-bottom: 10px;
  display: block;
}

.footer-info h3 {
  margin-bottom: 15px;
}
.download-app img {
  width: 40%;
  margin-right: 10px;
}
.main-footer {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 40px 0;
}

.main-footer img {
  width: 55%;
  margin-bottom: 18px;
}

.main-footer p {
  font-size: 15px;
  padding-right: 15%;
  /* font-weight: 500; */
}

.main-footer h4 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 15px;
}

.main-footer ul {
  padding-left: 0px;
  list-style: none;
}

.main-footer ul li a {
  font-size: 15px;
  color: #333;
}

.main-footer ul li {
  margin-bottom: 8px;
}

.our-payment .text-center {
  display: flex;
}
.our-payment {
  padding: 30px 0;
}
.our-payment p {
  margin: 0 20px 0 0;
}
.our-payment img {width: 45%;}
.copyright {
  background: #f2f3f5;
  padding: 30px 0;
}

.copyright p {
  margin: 0;
}

.copyright ul {
  display: flex;
  list-style: none;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
}

.copyright ul li {
  margin-left: 30px;
}
.copyright ul li:nth-child(1) {
  margin-left: 0px;
}
.copyright ul li a {
  color: #333;
  font-weight: 600;
}

.copyright ul li:hover a {
  color: #24aeb1;
}

/*.collapse {
    position: absolute;
    width: 180px;
    height: 400px;
    box-shadow: 0 0 10px #85888C;
    margin: -50px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;
    background-color: #F5F6FA;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}*/
.site-header-cart.menu {
  position: relative;
}
.site-header-cart.menu .count {
  position: absolute;
  top: -10px;
  left: 15px;
  background: #eb3a7b;
  width: 20px;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 50%;
  color: #fff;
}

/* Sign in Page */
.signin-main {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(./images/signin-medical-background.webp) center no-repeat;
  background-size: cover;
}
.signin-main .account-inner {
  margin: 0 auto;
  width: 380px;
  padding: 30px;
  overflow: auto;
  box-shadow: 0px 0px 6px 0px #24aeb1;
  border-radius: 10px;
  background: #fff;
}
.signin-main .account-inner img.brand-logo {
  width: 60%;
  margin-bottom: 30px;
}
.signin-main .account-inner label {
  margin-bottom: 0.5rem;
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  text-align: left;
}
.signin-main .account-inner input {
  line-height: normal !important;
  border-width: 1px;
  height: 40px;
  border: 1px solid #dfdfdf;
  width: 100%;
  border-radius: 5px;
  font-size: 15px;
  color: black;
  border: solid 1px rgb(36 174 177 / 40%);
  box-shadow: 0 0 5px rgb(36 174 177 / 40%) inset;
}
.signin-main .account-inner input::placeholder {
  font-size: 14px;
  color: black;
}
.signin-main .account-inner .verification-otp input {
  margin: 0 2px;
  text-align: center;
  line-height: 80px;
  font-size: 14px;
  border: solid 1px rgb(36 174 177 / 40%);
  box-shadow: 0 0 5px rgb(36 174 177 / 40%) inset;
  outline: none;
  width: 13% !important;
  transition: all 0.2s ease-in-out;
  border-radius: 3px;
  padding: 10px;
}
.signin-main .account-inner .verification-otp.wrong-otp input {
  border: solid 1px rgb(177 36 36 / 40%);
  box-shadow: 0 0 5px rgb(177 36 36 / 40%) inset;
}
.signin-main .account-inner .btn.btn-primary {
  width: 50% !important;
  margin: 0 auto 30px;
}
.signin-main .account-inner p {
  display: inline-block;
}
.signin-main .account-inner input.input__prefix {
  width: 20%;
  margin: 0;
  border-radius: 5px 0 0 5px;
  background: #dfdfdf;
  float: left;
}
.signin-main .account-inner input.input__phoneno {
  width: 80%;
  margin: 0;
  border-radius: 0 5px 5px 0;
  float: left;
}
.signin-main .account-inner input.input__phoneno:focus {
  outline: none;
  border-color: #24aeb1;
}
.signin-main .account-inner .card {
  border: none;
}
.signin-main .account-inner .card .Success-icon {
  border-radius: 200px;
  height: 150px;
  width: 150px;
  background: #e1e7d8;
  margin: 0 auto;
}
.signin-main .account-inner .card i {
  color: #789152;
  font-size: 70px;
  line-height: 150px;
  margin-left: -15px;
}
.signin-main .account-inner .card h1 {
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 10px;
}
/* Sign in Page End */

/* Cart Page */
.cart-main {
  background: #f7f7f7;
}
.cart-main h1 {
  font-size: 24px;
}
.cart-product {
  width: 100%;
  margin: 0;
  background: #fff;
  border-radius: 6px;
  margin-top: 20px;
}
.cart-product h4 {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: rgba(21, 27, 57, 0.6);
  line-height: 16px;
  padding: 16px 16px 8px 24px;
  display: inline-block;
  width: 100%;
  letter-spacing: 1px;
  margin: 0;
  text-transform: uppercase;
}
.product-itemdetails {
  padding: 16px 0;
  margin: 0 16px !important;
  border-bottom: 1px solid rgba(21, 27, 57, 0.1);
}
.product-itemdetails:last-child {
  border-bottom: none;
}
.leftside-icons {
  max-width: 45px;
}
.product-itemdetails .product-item-photo {
  float: left;
  padding: 0;
  position: relative;
  top: 0;
  left: 0;
}
.product-itemdetails .product-item-photo .pro-img {
  width: 100%;
}
.product-itemdetails .rightside-details .product-item-name {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  letter-spacing: 0.15px;
}
.product-itemdetails .rightside-details .product-item-name a {
  text-decoration: none;
  word-break: break-word;
  color: #151b39;
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
}
.product-itemdetails .rightside-details .product-item-name .item-stockqty {
  font-size: 12px;
  color: #378f30;
}
.product-itemdetails .rightside-details .catag-name {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: rgba(21, 27, 57, 0.6);
}
.product-itemdetails .rightside-details .catag-name .form {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: rgba(21, 27, 57, 0.6);
  font-style: italic;
}
.product-itemdetails .rightside-details .item-prices .discount-val {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  color: #ef4281;
  line-height: 24px;
  padding: 0;
  letter-spacing: 0.15px;
  font-weight: 600;
}
.product-itemdetails .rightside-details .item-prices .mrp-price {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: rgba(21, 27, 57, 0.6);
  line-height: 16px;
  padding: 0;
  margin-left: 5px;
  text-decoration: line-through;
}
.product-itemdetails .rightside-details .item-qty {
  text-align: right;
}
.product-itemdetails .rightside-details .item-qty .pro-qty {
  position: relative;
}
.product-itemdetails .rightside-details .item-qty .pro-qty span {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: rgba(21, 27, 57, 0.6);
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: absolute;
  right: 65px;
  top: 4px;
}
.product-itemdetails .rightside-details .item-qty .pro-qty input.qty {
  width: 55px;
  padding: 0 10px;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
}
.product-itemdetails .rightside-details .deliveryby .date {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #151b39;
  border-right: 1px solid rgba(21, 27, 57, 0.2);
  margin-right: 15px;
  padding: 8px 0 0;
}
.product-itemdetails .rightside-details .deliveryby .maxqtymsg {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: #e23911;
  line-height: 16px;
}
.product-itemdetails
  .rightside-details
  .deliveryby
  .remove-drug
  .action-delete {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: rgba(21, 27, 57, 0.6);
  line-height: 16px;
  letter-spacing: 1px;
  background: #f6f6f7;
  padding: 8px 0;
  text-transform: uppercase;
  float: left;
  width: 36%;
  text-align: center;
  margin: 0 4% 0 0;
  border-radius: 4px;
}
.product-itemdetails
  .rightside-details
  .deliveryby
  .remove-drug
  .saveforlaterbtn {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: rgba(21, 27, 57, 0.6);
  line-height: 16px;
  letter-spacing: 1px;
  background: #f6f6f7;
  padding: 8px;
  text-transform: uppercase;
  float: right;
  width: 45%;
  text-align: center;
  margin: 0px;
  border-radius: 4px;
}
.cntshoppingbox {
  margin-bottom: 24px;
  padding: 21px 16px;
  text-align: left;
}
.cntshoppingbox button {
  font-family: "Lato", sans-serif;
  font-size: 13px;
  color: #24aeb1;
  line-height: 20px;
  letter-spacing: 1px;
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  width: 100%;
  text-align: left;
  background: transparent;
  border: none;
}


.cntshoppingbox button::after {
  content: "+";
  float: right;
  font-family: "Lato", sans-serif;
  font-size: 28px;
  color: #24aeb1;
}
.product-itemdetails .rightside-details .action.action-delete.removeitem {
  position: absolute;
  right: 0;
  top: 0;
  background: #eb3a7b;
  color: #fff;
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 50%;
}
.product-itemdetails .rightside-details .action.action-delete.removeitem i {
  font-family: "Font Awesome 5 Free";
  font-size: 15px;
  font-weight: normal;
  line-height: 36px;
}
.cart-main .sticky-top {
  margin-top: 50px;
}
/*.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
}*/
.totalamt-col {
  display: inline-block;
  background: #fff;
  padding: 16px 8px 0;
  width: 100%;
  border-radius: 8px;
}
.totalamt-col .paymentdetails-title {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: rgba(21, 27, 57, 0.6);
  padding: 0 0 20px 16px;
  display: inline-block;
  width: 100%;
  margin: 0;
  letter-spacing: 1px;
}
.totalamt-col .allcalculation {
  width: 100%;
}
.totalamt-col .allcalculation .subtoal,
.totalamt-col .allcalculation .cart-discount,
.totalamt-col .allcalculation .net-amount {
  padding: 0 16px;
  display: inline-block;
  width: 100%;
}
.totalamt-col .allcalculation .net-amount {
  font-weight: 600;
}
.totalamt-col .allcalculation label {
  background: 0 0;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #151b39;
  line-height: 24px;
  margin: 0;
  float: left;
  text-align: left;
}
.totalamt-col .allcalculation span {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #151b39;
  line-height: 24px;
  float: right;
}
.totalamt-col .allcalculation .save-amount {
  clear: both;
  margin: 10px 15px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  color: #378f30;
  line-height: 16px;
  background: #f3f8ec;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 12px 20px;
  font-weight: 600;
}
.process-col {
  background: #fff;
  padding: 16px 8px;
  display: inline-block;
  width: 100%;
  border-radius: 0 0 8px 8px;
  z-index: 2;
}
.process-col .totalamt {
  float: left;
  width: 45%;
  padding-left: 10px;
}
.process-col .totalamt .text {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  color: rgba(21, 27, 57, 0.6);
  padding: 0 0 3px;
  display: block;
  letter-spacing: 0.83px;
  font-weight: 600;
}
.process-col .totalamt .save-price {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  color: #151b39;
  display: block;
}
.process-col .process-checkout {
  float: right;
  width: 50%;
}
.process-col .process-checkout .btn_to_checkout {
  background: #24aeb1;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #fff;
  border-radius: 4px;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 10px 6px;
  width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
  cursor: pointer;
}
.taxes-txt {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  color: rgba(21, 27, 57, 0.6);
  font-style: italic;
  text-align: left;
  padding: 16px;
}

.checkout-main .product-itemdetails .rightside-details .item-qty .pro-qty span {
  right: 25px;
}
.checkout-main .product-itemdetails .rightside-details .deliveryby .date {
  border: none;
}

.checkout-form {
  padding: 15px 30px 30px;
}
.checkout-form form input {
  width: 100%;
  height: 40px;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}
.checkout-form form textarea {
  width: 100%;
  height: 120px;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}
.checkout-form form .col-lg-6,
.checkout-form form .col-lg-12,
.checkout-form form .col-lg-4 {
  padding: 0 10px;
}
.checkout-form form input[type="submit"] {
  background: #24aeb1;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #fff;
  border-radius: 4px;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 10px 6px;
  width: 20%;
  height: 100%;
  margin: 0;
  border: 0;
  cursor: pointer;
}

/* Cart Page End */

/* Shop Page */
.breadcrum-navigation {
  margin-bottom: 30px;
}
.breadcrum-navigation ul {
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}
.breadcrum-navigation ul li {
  padding-right: 30px;
  position: relative;
}
.breadcrum-navigation ul li:before {
  content: "\f105";
  position: absolute;
  top: 4px;
  right: 12px;
  font-family: "FontAwesome";
  font-size: 12px;
}
.breadcrum-navigation ul li:last-child:before {
  content: none;
}
.breadcrum-navigation ul li a {
  color: #343434;
}
.breadcrum-navigation ul li.active a {
  color: #274888;
}

.shop-page {
  background: #f7f7f7;
}
.product-img-wrap.top-to-bottom .product-image img {
  width: 100%;
}
.shop-page .col-lg-4 {
  margin-bottom: 30px;
}
.sidebar_widget {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 30px;
}

.sidebar_widget ul.product-categories {
  list-style: none;
  margin: 0;
}

.sidebar_widget h3.widget-title {
  font-size: 18px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dddddd;
}

.sidebar_widget ul.product-categories li a {
  color: #232323;
  font-size: 15px;
}

.sidebar_widget ul.product-categories li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 5px;
}
.sidebar_widget ul.product-categories li:before {
  content: "\f105";
  position: absolute;
  top: 4px;
  left: 0px;
  font-family: "FontAwesome";
  font-size: 12px;
  -ms-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
.sidebar_widget ul.product-categories li:hover:before {
  left: 8px;
  -ms-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.sidebar_widget .filter-price {
  width: 100%;
  border: 0;
  padding: 0;
  margin: 0;
}
.sidebar_widget .price-title {
  position: relative;
  color: #232323;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 600;
  margin-right: 15px;
  background: transparent;
}
.sidebar_widget .price-field {
  position: relative;
  width: 100%;
  height: 55px;
  box-sizing: border-box;
  background: rgb(122 112 82 / 0%);
  padding-top: 25px;
  padding-left: 0;
  border-radius: 3px;
}
.sidebar_widget .price-field input[type="range"] {
  position: absolute;
}
.sidebar_widget .price-field input[type="range"] {
  width: 100%;
  height: 2px;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  border-radius: 5px;
  pointer-events: none;
  -webkit-appearance: none;
  color: #232323;
  background: #24aeb1;
}
.sidebar_widget .price-field input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
.sidebar_widget .price-field input[type="range"]:active,
.sidebar_widget .price-field input[type="range"]:focus {
  outline: 0;
}
.sidebar_widget .price-field input[type="range"]::-ms-track {
  width: 100%;
  height: 2px;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  border-radius: 5px;
  pointer-events: none;
  background: transparent;
  border-color: transparent;
  color: transparent;
  border-radius: 5px;
}
.sidebar_widget .price-wrap {
  display: flex;
  justify-content: left;
  color: #232323;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 400;
  margin-bottom: 7px;
}
.sidebar_widget .price-wrap-1,
.sidebar_widget .price-wrap-2 {
  display: flex;
}
.sidebar_widget .price-wrap_line {
  margin: 0 10px;
}
.sidebar_widget .price-wrap #one,
.sidebar_widget .price-wrap #two {
  width: 30px;
  text-align: right;
  margin: 0;
  padding: 0;
  margin-right: 2px;
  background: 0;
  border: 0;
  outline: 0;
  color: #fff;
  font-family: "Karla", "Arial", sans-serif;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 400;
}
.sidebar_widget .price-wrap label {
  text-align: right;
}

.price-field input[type="range"]::-webkit-slider-thumb {
  position: relative;
  -webkit-appearance: none;
  margin: 0;
  border: 0;
  outline: 0;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-top: -2px;
  background-color: #24aeb1;
  cursor: pointer;
  cursor: pointer;
  pointer-events: all;
  z-index: 100;
}

.price-field input[type="range"]::-moz-range-thumb {
  position: relative;
  appearance: none;
  margin: 0;
  border: 0;
  outline: 0;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-top: -5px;
  background-color: #24aeb1;
  cursor: pointer;
  cursor: pointer;
  pointer-events: all;
  z-index: 100;
}

.price-field input[type="range"]::-ms-thumb {
  position: relative;
  appearance: none;
  margin: 0;
  border: 0;
  outline: 0;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-top: -5px;
  background-color: #274888;
  cursor: pointer;
  cursor: pointer;
  pointer-events: all;
  z-index: 100;
}
.sidebar_widget ul.product-tag {
  list-style: none;
}
.sidebar_widget ul.product-tag li {
  display: inline-block;
  padding: 5px 12px;
  border: 1px solid #ddd;
  border-radius: 30px;
  margin-bottom: 6px;
}
.sidebar_widget ul.product-tag li a {
  font-size: 14px;
  color: #232323;
}
.sidebar_widget ul.product-tag li:hover a {
  color: #24aeb1;
}

.shop-pagination {
  padding-top: 15px;
}
.shop-pagination .page-numbers {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  margin: 2px;
  font-weight: 600;
  font-size: 12px;
  color: #24aeb1;
}
.shop-pagination .page-numbers:hover {
  background-color: #24aeb1;
  color: #fff;
}
.shop-pagination .page-numbers.current {
  background-color: #24aeb1;
  color: #fff;
}
.shop-pagination .page-numbers.next {
  color: #232323;
  margin-left: 20px;
}
.shop-pagination .page-numbers.next i {
  margin-left: 10px;
}
.shop-pagination .page-numbers.next:hover {
  background: none;
  color: #24aeb1;
}

/* Shop Page End */

/* Blog Main Page */
.blog-page {
  position: relative;
  background: #f7f7f7;
}
.blog-page .shop-pagination {
  padding-top: 50px;
}
.blog-page .post-inner {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
}
.blog-page .post-inner .post-thumbnail {
  overflow: hidden;
  border-radius: 10px;
  display: block;
}
.blog-page .post-inner .post-thumbnail a img {
  -ms-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -o-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -moz-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -webkit-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
.blog-page .post-inner:hover .post-thumbnail a img {
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  -ms-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -o-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -moz-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -webkit-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}

.blog-page .post-inner .post-content .entry-title {
  font-size: 28px;
  font-weight: 500;
  padding-top: 0;
  margin-bottom: 20px;
  position: relative;
  flex: 0 0 100%;
}
.blog-page .post-inner .post-content .entry-title a {
  color: #1d2a38;
}
.blog-page .post-inner:hover .post-content .entry-title a {
  color: #24aeb1;
}
.blog-page .post-inner .post-content .entry-meta {
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  color: #aaa;
  position: relative;
  margin-bottom: 0.95rem;
}

.blog-page .post-inner .post-content {
  padding: 30px;
}
.blog-page .post-inner .post-content .entry-meta a {
  color: #24aeb1;
}

.blog-page .post-inner .post-content .entry-content a {
  font-size: 13px;
  margin-right: auto;
  align-items: center;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  width: 20%;
  height: auto;
  display: flex;
  padding: 0 20px 0 0;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-image: linear-gradient(to right, #24aeb1 0, #24aeb1 100%);
  position: relative;
  z-index: 1;
  border-radius: 30px;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-repeat: no-repeat;
  background-size: 0 100%;
  color: #1d2a38;
}
.blog-page .post-inner .post-content .entry-content a:before {
  content: "\f291";
  width: 40px;
  height: 40px;
  border-radius: 40px;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 15px;
  z-index: 1;
  background-color: #24aeb1;
  font-family: "FontAwesome";
  font-weight: normal;
}
.blog-page .post-inner .post-content .entry-content a:hover {
  color: #fff;
  background-size: 100% 100%;
}
.blog-page .post-inner .post-content .entry-content a:hover:before {
  text-indent: 15px;
}
.sidebar_widget .sidebar-blog {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 15px;
}

.sidebar_widget .sidebar-blog .blog-deatils-sidebar {
  padding-left: 15px;
}
.sidebar_widget .sidebar-blog img {
  width: 100px;
}
.sidebar_widget .sidebar-blog .blog-deatils-sidebar span.posted-on {
  font-size: 12px;
}
.sidebar_widget .sidebar-blog .blog-deatils-sidebar h4.entry-title {
  font-size: 17px;
}
.sidebar_widget .sidebar-blog .blog-deatils-sidebar h4.entry-title a {
  color: #232323;
}
.post-footer ul.product-tag {
  list-style: none;
}
.post-footer ul.product-tag li {
  display: inline-block;
  padding: 5px 12px;
  border: 1px solid #ddd;
  border-radius: 30px;
  margin-bottom: 6px;
}
.post-footer ul.product-tag li a {
  font-size: 14px;
  color: #232323;
}
.post-footer ul.product-tag li:hover a {
  color: #24aeb1;
}
.post-footer {
  margin-top: 40px;
  border-top: 1px solid #ddd;
  padding-top: 30px;
}
.post-footer ul.post-socials {
  list-style: none;
  float: right;
}
.post-footer ul.post-socials li {
  display: inline-block;
  padding: 5px 12px;
  border-radius: 30px;
  margin-bottom: 6px;
}
.post-footer ul.post-socials li a {
  font-size: 14px;
  color: #232323;
}
.post-footer ul.post-socials li:hover a {
  color: #24aeb1;
}
.blog-comments {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 30px;
}
.blog-comments h3 {
  font-size: 26px;
  margin-bottom: 30px;
}
.blog-comments .blog-comments-profile {
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.blog-comments .blog-comments-profile .col-lg-1 {
  padding-right: 0;
}
.blog-comments .blog-comments-profile img {
  border-radius: 50%;
  width: 100%;
}
.blog-comments .blog-comments-profile .entry-meta {
  margin-bottom: 10px;
}
.blog-comments .blog-comments-profile .entry-meta span.posted-on {
  font-size: 14px;
  font-weight: 600;
}
.blog-comments .blog-comments-profile .entry-meta span.posted-on a {
  font-weight: normal;
  margin-left: 5px;
  color: #a1a1a1;
}
.blog-comments .blog-comments-profile .col-lg-11 .row {
  border-top: 1px solid #ddd;
  padding-top: 30px;
  margin-top: 30px;
}
.blog-comments .blog-comments-profile a.comments-btn {
  color: #232323;
  font-weight: 600;
  position: relative;
  font-size: 13px;
}
.blog-comments .blog-comments-profile a.comments-btn:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #232323;
  bottom: -4px;
}
.blog-comments .blog-comments-profile a.comments-btn:hover {
  color: #24aeb1;
}
.blog-comments .leave-comment {
  margin-top: 50px;
}
.blog-comments .leave-comment h3 {
  margin-bottom: 10px;
}
.blog-comments .leave-comment span.mandatory-required {
  color: #f00;
}
.blog-comments .leave-comment form input {
  width: 31%;
  float: left;
  padding: 0px 10px;
  margin: 0 2% 2% 0;
  height: 42px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}
.blog-comments .leave-comment form textarea {
  width: 97%;
  padding: 10px;
  height: 130px;
  border-radius: 5px;
  border: 1px solid #ddd;
}
.blog-comments .leave-comment form .comment-checkbox {
  text-align: left;
  margin-bottom: 20px;
}
.blog-comments .leave-comment form .comment-checkbox input[type="checkbox"] {
  width: auto;
  margin: 6px 12px 0 0 !important;
  height: auto;
}
.blog-comments .leave-comment form .comment-checkbox p {
  margin: 0;
}
.blog-comments .leave-comment form button.post-comment-btn {
  background: #24aeb1;
  color: #fff;
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
}
.blog-comments .leave-comment form button.post-comment-btn:hover {
  background: #274888;
  color: #fff;
}
/* Blog End */

/* Search Result */
.search-result {
  background: #f7f7f7;
}
.search-result .product-details {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
}
.search-result h1 {
  font-size: 24px;
  margin-bottom: 20px;
}
.search-result .leftside-icons {
  max-width: 70px;
}
.search-result .product-itemdetails .product-item-photo {
  width: 65px;
}
.search-result .product-itemdetails .product-item-photo .pro-img {
  width: 100%;
}
.product-itemdetails
  .rightside-details
  .item-prices
  .discount-val
  span.best-price {
  font-size: 15px;
  font-weight: 500;
  color: #484848;
}
.product-itemdetails .rightside-details .shop-action a.add_to_cart_button {
  background: #24aeb1;
  border-radius: 4px;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 40px;
  padding: 0px 15px 0px 15px;
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
  float: right;
}
.product-itemdetails .rightside-details .shop-action a.add_to_cart_button:hover {
  background: #274888;
}

/* Search Result End */

/* My Account */
.edit-account {
  background: #f7f7f7;
}
.edit-account h1 {
  font-size: 26px;
  margin-bottom: 20px;
}
.edit-account .cart-product:nth-child(1) {
  margin-top: 0;
  padding-top: 10px;
}

/* My Account End */

/* Check Order Status */
.check-order-order .card {
  z-index: 0;
  background-color: #ffffff;
  padding-bottom: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
}

.check-order-order .top {
  padding-top: 40px;
  padding-left: 13% !important;
  padding-right: 13% !important;
}

/*Icon progressbar*/
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: #455a64;
  padding-left: 0px;
  margin-top: 30px;
}

#progressbar li {
  list-style-type: none;
  font-size: 13px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
}

#progressbar .step0:before {
  font-family: FontAwesome;
  content: "\f10c";
  color: #fff;
}

#progressbar li:before {
  width: 40px;
  height: 40px;
  line-height: 42px;
  display: block;
  font-size: 20px;
  background: #6bdadd;
  border-radius: 50%;
  margin: auto;
  padding: 0px;
}

/*ProgressBar connectors*/
#progressbar li:after {
  content: "";
  width: 100%;
  height: 12px;
  background: #6bdadd;
  position: absolute;
  left: 0;
  top: 16px;
  z-index: -1;
}

#progressbar li:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  left: -50%;
}

#progressbar li:nth-child(2):after,
#progressbar li:nth-child(3):after {
  left: -50%;
}

#progressbar li:first-child:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  left: 50%;
}

#progressbar li:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

#progressbar li:first-child:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

/*Color number of the step and the connector before it*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #24aeb1;
}

#progressbar li.active:before {
  font-family: FontAwesome;
  content: "\f00c";
}

.check-order-order .icon {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

.check-order-order .icon-content {
  padding-bottom: 20px;
}

/* Check Order Status */

/* Shop page CSS */
i {
  font-family: "FontAwesome" !important;
}
.Shop_product {
  display: flex;
}
.main_product {
  text-align: center;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
}
.shop_detail_main {
  background-color: #f2f3f5;
}
.product_item {
  border: 1px solid #e6e6e6;
  padding: 0 5px;
  margin: 5px;
}
.product_item:active {
  border: 1px solid #24aeb1;
}
.product_title {
  text-align: left;
  font-size: 1.75rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
}
.main_star {
  display: flex;
  align-content: flex-start;
  flex-direction: row;
  align-items: baseline;
}
.main_star .star-rating {
  font-size: 1rem;
}
.main_star span {
  font-size: 12px;
}
.main_product .col-lg-6 {
  padding-top: 30px;
}
.Shop_product_inner .price {
  text-align: left;
  color: #eb3a7b;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
  padding-top: 12px;
}
.Shop_product_inner .product_description {
  color: #7d879c;
  text-align: left;
  font-family: "Lato", sans-serif;
  margin-bottom: 25px;
}
.Quantity input {
  width: 40px;
  border: none;
  text-align: center;
}
.Quantity button {
  width: 40px;
  border: none;
  background: transparent;
  font-size: 18px;
  font-weight: 600;
}
.Quantity {
  text-align: left;
  border: 2px solid #ddd;
  border-radius: 30px;
  padding: 10px 6px;
}
.Quantity a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  padding-left: 10px;
  border: 1px solid #007abb;
  border-radius: 5px;
  padding: 10px;
}
.product_meta {
  text-align: left;
  padding-top: 50px;
}
.sku ul {
  list-style: none;
  margin: 0;
}
.sku ul li {
  padding-left: 10px;
}
.sku_wrapper2 {
  display: flex;
  align-items: center;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 600;
}
.sku {
  color: #7d879c;
  font-size: 13px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
  margin-left: 5px;
}
.sku_wrapper {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 600;
}
.sku i {
  color: #7d879c;
  font-size: 12px;
  font-weight: 600;
}
.inner_meta {
  padding-top: 5px !important;
}
.Shop_product_inner a.add-to-cart {
  background: #24aeb1;
  border-radius: 30px;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 48px;
  padding: 0px 30px 0px 30px;
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
  margin-left: 30px;
}
.Shop_product_inner a.add-to-cart:hover {
  background: #274888;
}
.Product_details {
  background-color: #f2f3f5;
}
.description_details {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
}
.description_para {
  color: #7d879c;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0;
  margin: 0px 0px 24px;
  padding-top: 20px;
}
.description_para2 {
  color: #7d879c;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0;
  margin: 0px 0px 24px;
}
.description_heading {
  color: #1d2a38;
  line-height: 1.2;
  margin: 0 0 0.5em;
  padding: 1.5rem 0 0;
  font-weight: 600;
  font-family: "Lato", sans-serif;
  font-size: 1.5rem;
}
.product_feature {
  color: #1d2a38;
  margin: 0 0 0.5em;
  padding: 1.5rem 0 0;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.c-primary {
  color: #24aeb1;
  line-height: 1.2;
  font-family: "Lato", sans-serif;
  font-size: 16px;
}
#home p {
  color: #7d879c;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0;
  margin: 0px 0px 24px;
}
.product_heading {
  color: #1d2a38;
  line-height: 1.2;
  margin: 0 0 0.5em;
  padding: 1.5rem 0 0;
  font-weight: 600;
  font-family: "Lato", sans-serif;
  font-size: 1.5rem;
}
#home strong {
  font-weight: 600;
  font-family: "Lato", sans-serif;
  padding-top: 10px;
}
.description_details .nav-link {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 28px;
  padding-bottom: 26px;
  color: #1d2a38;
  background-color: #fff !important;
}
.description_details .nav-link.active {
  color: #24aeb1 !important;
  background-color: #fff !important;
  border-bottom: 3px solid #24aeb1 !important;
  border-radius: 1px;
}
.nav-link.hover {
  color: #24aeb1 !important;
  background-color: #fff !important;
  border-bottom: 3px solid #24aeb1 !important;
  border-radius: 1px;
}
.nav-tabs {
  border: #fff;
}
.nav-tabs li.nav-item {
  margin-right: 20px;
}
.nav-tabs .nav-link.active {
  border: #fff;
}
.nav-tabs .nav-link {
  border: #fff;
}
.table_content_alt {
  background-color: rgba(245, 246, 250, 0.7);
}
.table_content td {
  color: #7d879c;
}
#profile {
  padding-top: 30px !important;
}
#contact .main_star {
  justify-content: space-between;
}
#contact {
  padding-top: 30px !important;
}
#contact .main_star .star-rating {
  font-size: 1.5rem;
}
#contact .card-header {
  background-color: #fff !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  border: none;
}
#contact .card {
  border: #fff;
}
.blockquote P {
  color: #7d879c;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0;
  margin: 0px 0px 24px;
}
.blockquote-footer {
  color: #1d2a38;
  font-size: 0.8125rem;
  font-weight: 500;
  text-transform: uppercase;
}
.you_may_also_like {
  background-color: #f2f3f5;
}
.inner_you_may {
  background-color: #fff;
  padding: 30px;
}
.inner_you_may .col-lg-12 {
  display: flex;
}
.inner_you_may .col-lg-3 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.inner_you_may h3 {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.caption .star-rating {
  justify-content: left;
  font-size: 1.2em;
}
.caption.star-rating label {
  color: #fc0;
}

.caption .price_you ins,
.caption .price_you ins {
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  color: #7d879c;
}
.add-wishlist-button a:hover {
  border-color: #24aeb1;
  background-color: #24aeb1;
}

.compare-button a:hover {
  border-color: #24aeb1;
  background-color: #24aeb1;
}
.quick-view-button a:hover {
  border-color: #24aeb1;
  background-color: #24aeb1;
}

/* Media CSS */
@media (max-width: 1024px) {
  header .col-lg-3.text-right {
    padding-top: 0px;
  }
  .account {
    margin-bottom: 16px;
  }
  .feature-wrap {
    width: 1000px;
  }
  .feature-wrap ul li i {
    width: 70px;
  }
  .home-blog .owl-carousel .post-inner .post-content .entry-content a {
    width: 70%;
  }
  .newsletter form input[type="submit"] {
    width: 22%;
  }
  .footer-info .info-details {
    width: 70%;
  }
  .copyright ul li {
    margin-left: 25px;
  }
  .copyright ul li a {
    font-weight: 500;
  }
  .deal-the-day .product-block {
    padding: 15px 0;
  }
  .sidebar_widget .sidebar-blog img {
    width: 170px;
  }
  .sidebar_widget .sidebar-blog {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .main_product img {
    width: 100%;
  }
  .description_details .nav-link {
    font-size: 1.3rem;
  }
}
@media (max-width: 768px) {
  .offers-section .offers-section-inner {
    margin-bottom: 20px;
  }
  .feature-wrap {
    width: 700px;
    bottom: -75px;
    padding: 15px 0px;
  }
  .feature-wrap ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
  }
  .feature-wrap ul li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    justify-content: space-around;
  }
  .feature-wrap ul li i {
    width: 40px;
    height: 40px;
  }
  .feature-wrap ul li h4 {
    padding: 10px 0px 0;
  }
  .deal-the-day .product-block {
    padding: 15px 0;
  }
  .star-rating {
    margin-top: 5px;
  }
  .star-rating label {
    line-height: 22px;
  }
  .caption .price {
    margin-bottom: 0rem;
  }
  .home-blog .owl-carousel .post-inner .post-content .entry-content a {
    width: 70%;
  }
  .newsletter form input[type="submit"] {
    width: 26%;
  }
  .footer-info .info-details {
    margin-top: 12px;
    width: 100%;
  }
  .footer-info .info-details h5 a {
    font-size: 20px;
  }
  .footer-info i {
    font-size: 20px;
  }
  .footer-info h3 {
    font-size: 22px;
  }
  .download-app img {
    width: 55%;
    margin-bottom: 8px;
  }
  .foot-about {
    margin-bottom: 20px;
  }
  .main-footer img {
    width: 35%;
  }
  .copyright {
    padding: 20px 0;
    text-align: center;
  }
  .copyright ul {
    justify-content: center;
    margin-top: 15px;
  }
  button.navbar-toggler {
    display: block;
    background-color: #ffffff;
  }
  header .main-header .col-lg-3.text-right {
    display: none;
  }
  .search-box {
    width: 78%;
    display: inline-block;
  }
  nav.navbar.navbar-expand-lg {
    display: inline-block;
    text-align: right;
    float: right;
    margin-top: 10px;
  }
  .navbar-nav {
    flex-direction: column;
    flex-wrap: nowrap;
    display: flex;
    align-items: flex-start;
  }
  nav.navbar ul li.nav-item {
    padding: 0 15px 10px;
  }
  .offcanvas-collapse {
    position: fixed;
    top: 0px;
    bottom: 0;
    right: 100%;
    left: -300px;
    width: 300px;
    padding: 4rem 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #274888;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    transition-property: left, visibility;
    z-index: 999999;
  }
  .offcanvas-collapse {
    align-items: start;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
    border-right: 5px solid rgba(0, 0, 0, 0.2);
  }
  .offcanvas-collapse.open {
    left: 0;
    visibility: visible;
  }
  .navbar-toggler-icon {
    background-image: url(./images/navbar-bar.svg);
  }
  .navbar-toggler-icon.close {
    background-image: url(./images/navbar-close.svg);
  }
  .offcanvas-collapse button#navToggle {
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 0 10px;
  }
  .offcanvas-collapse button#navToggle span.navbar-toggler-icon {
    width: 20px;
    opacity: 1;
  }
  #home-banner .item {
    min-height: 400px;
  }
  #home-banner .home-banner-content h2 {
    font-size: 36px;
  }
  .sidebar_widget .sidebar-blog .blog-deatils-sidebar {
    padding-left: 0px;
  }
}
@media (max-width: 425px) {
  .newsletter form {
    width: 100%;
  }
  .offers-section .offers-section-inner {
    background: url(./images/banner-h3-01.jpg) rgb(0 0 0 / 30%) center no-repeat;
    background-blend-mode: darken;
  }
  .feature-brand .owl-carousel .item {
    padding: 15px 10px;
  }
  .feature-brand h3,
  .health-product h3,
  .deal-the-day h3,
  .health-product h3,
  .popular-categories h3,
  .home-blog h3 {
    font-size: 22px;
  }
  .feature-brand .owl-carousel .owl-nav button,
  .health-product .owl-carousel .owl-nav button,
  .deal-the-day .owl-carousel .owl-nav button,
  .health-product .owl-carousel .owl-nav button,
  .popular-categories .owl-carousel .owl-nav button,
  .home-blog .owl-carousel .owl-nav button {
    width: 32px;
    height: 32px;
    line-height: 16px;
    font-size: 23px;
  }
  header .top-header ul {
    justify-content: flex-start;
    margin-top: 5px;
  }
  .star-rating {
    font-size: 1.1em;
  }
  .feature-brand .owl-carousel .owl-nav,
  .health-product .owl-carousel .owl-nav,
  .deal-the-day .owl-carousel .owl-nav,
  .health-product .owl-carousel .owl-nav,
  .popular-categories .owl-carousel .owl-nav,
  .home-blog .owl-carousel .owl-nav {
    top: auto;
    right: 0;
    left: 0;
  }
  .product-footer .shop-action a {
    font-size: 0px;
  }
  .product-footer .shop-action a:before {
    font-size: 14px;
  }
  .caption .price {
    font-size: 14px;
  }
  .price del {
    margin-left: 0;
    margin-bottom: 3px;
  }
  .product-transition {
    padding-top: 15px;
  }
  .group-label {
    top: 0;
    left: 0px;
  }
  .product-transition .group-action {
    top: 0px;
    right: 0px;
  }
  .footer-info .info-details {
    margin-top: 0px;
    width: 75%;
  }
  .footer-info .col-md-4 {
    margin-bottom: 20px;
  }
  .footer-info .responsive-center {
    text-align: center;
  }
  .download-app img {
    width: 36%;
  }
  .main-footer {
    text-align: center;
  }
  .main-footer img {
    width: 65%;
  }
  .main-footer p {
    padding: 0px;
  }
  .main-footer h4 {
    margin-top: 20px;
  }
  .our-payment .text-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .our-payment p {
    margin: 0 0px 15px 0;
  }
  .our-payment img {
    width: 100%;
  }
  .copyright ul {
    flex-wrap: wrap;
  }
  .copyright ul li a {
    font-size: 14px;
  }
  .search-box {
    display: none;
  }
  nav.navbar.navbar-expand-lg {
    margin-top: 0px;
  }
  header .main-header .col-lg-2 img {
    margin-top: 0px;
  }
  .feature-wrap {
    display: none;
  }
  #home-banner .home-banner-content a.banner-cta {
    margin-top: 10px;
  }
  #home-banner .home-banner-content h4 {
    font-size: 16px;
  }
  #home-banner .home-banner-content h2 {
    font-size: 28px;
    margin-top: 20px;
  }
  #home-banner .owl-nav {
    top: 30%;
  }
  #home-banner .home-banner-content .row .col-lg-6.d-flex {
    margin-bottom: 10px;
  }
  .post-footer ul.post-socials {
    float: left;
  }
  .sidebar_widget .search-box {
    display: block;
    width: 100%;
  }
  .blog-comments .leave-comment form input,
  .blog-comments .leave-comment form textarea {
    width: 100%;
  }
  .search-box .form-inline button.btn {
    top: -5px;
  }
}

.outletClass {
  margin-top: 165px;
  background: #f2f3f5;
}

/* .carouselProducts ul li {
  width: 200px !important;
  height: 450px;
}

.brandNameDashboard ul li {
  width: 200px !important;
  height: 450px;
} */

#detailImg {
  height:100px !important;
  width:100px !important;
}
#detailImgMain {
  height:500px !important;
  width:500px !important;
}


.react-multi-carousel-list.carouselProducts {
  overflow-y: visible;
  position: inherit;
}
.react-multi-carousel-list.carouselProducts .react-multiple-carousel__arrow {
  z-index: 999;
  min-width: 38px;
  min-height: 38px;
  top: -65px;
  background: #dddddd;
}
.react-multi-carousel-list.carouselProducts .react-multiple-carousel__arrow:hover {background: #24aeb1;}
.react-multi-carousel-list.carouselProducts .react-multiple-carousel__arrow--right {
  right: calc(1% + 1px);
}
.react-multi-carousel-list.carouselProducts .react-multiple-carousel__arrow--left {
  left: calc(90% + 1px);
}
.react-multi-carousel-list.carouselProducts .react-multiple-carousel__arrow::before {color: #232323;}
.react-multi-carousel-list.carouselProducts .react-multiple-carousel__arrow:hover::before {color: #fff;}

/* Feature Brand */
.react-multi-carousel-list.brandNameDashboard {
  overflow-y: visible;
  position: inherit;
}
.react-multi-carousel-list.brandNameDashboard .react-multiple-carousel__arrow {
  z-index: 999;
  min-width: 38px;
  min-height: 38px;
  top: -65px;
  background: #dddddd;
}
.react-multi-carousel-list.brandNameDashboard .react-multiple-carousel__arrow:hover {background: #24aeb1;}
.react-multi-carousel-list.brandNameDashboard .react-multiple-carousel__arrow--right {
  right: calc(1% + 1px);
}
.react-multi-carousel-list.brandNameDashboard .react-multiple-carousel__arrow--left {
  left: calc(90% + 1px);
}
.react-multi-carousel-list.brandNameDashboard .react-multiple-carousel__arrow::before {color: #232323;}
.react-multi-carousel-list.brandNameDashboard .react-multiple-carousel__arrow:hover::before {color: #fff;}
.react-multi-carousel-list.brandNameDashboard ul li {
  padding: 0 15px;
}

.handleSendEmailOtp {
  font-family: "Lato", sans-serif;
  font-size: 13px;
  color: #24aeb1;
  line-height: 20px;
  letter-spacing: 1px;
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  width: 100%;
  text-align: left;
  background: transparent;
  border: none;
}

.customerDetailFormGlizmedLogo{
  height:80px;
}

.parentProfileUpdatedDiv {
  width:100%;
  text-align: center;
  justify-content: center;
}
.glizmedLogoProfileUpdateModal {
 
  width:300px;
  height:200px;
}

.updatedProfileBtn{
  padding:10px 30px;
  border:none;
  background-color:Green;
  border-radius:20px;
  color:white;
}

.whatsappDiv {
 
  height:100px;
 background-color: green;
 border-radius:10px;
 color:white;
 font-weight: bold;
 font-size:20px;
  
}

.callUsDiv {
  height:100px;
  background-color: orange;
  border-radius:10px;
  color:white;
  font-weight: bold; 
}

.uploadPrescriptionDiv {
  background-color: lightgrey;
  height:100px;
 border-radius:10px;
  color:white;
  font-weight: bold; 

}
.prestitle{
  display: flex;
  justify-content: center;

  align-items: center !important;
}
.inputbtn{
  margin: 6px;
  padding: 4px 20px;
  font-weight: 500;
  border-radius: 10px;
  border: none;
 background-color:#24aeb1  !important;
 color: #fff;
  /* background-color: rgb(134, 29, 29); */
  font-size: 16px;

}
.uploadprs{
  margin-top: 40px;
  text-align: center;
  font-weight: 500;
  font-size: 17px;
}
.uploadnow{
  margin: 30px;
  color:#24aeb1;
  font-weight: 500;
  /* background-color:#24aeb1 ; */
}
.backbutton{
  padding: 10px;
  background-color: #24adb1;
  color: #fff;
  border: none;
  border-radius: 4px;
}
.emailverified{
  margin: 1px;
}
.deliveryadd{
font-size: 13px;
font-weight: 500;
margin-left: 10px;
}
.closebtn{
  margin-left:450px;
 
}

.prestitle{
  margin-left:450px;
  color: #24adb1;
 
}
.uploadprescription{
  margin-top: 30px;
  border: 1px solid  #24adb1;
  margin-left:400px;
  width: 500px;
  height: 300px;
  background-color: #fff;
  border-radius: 15px ;
 
}
.inputfield{
  display: flex;
  align-items: center;
  margin-left:150px;
  text-align: center;
}
.Healthcategory{
  display: flex;
  justify-content: center;

 
}
.subCategoryList{
  background-color: red;
  border: 2px solid green;
}

.healthProductFilter.showProduct .product-block {width: 100%;margin-bottom: 30px;height:auto ;}
.caption .product__title {height: 55px;}
.caption .product__title span {
  width: 100%;
  display: block;
  float: none;
  overflow: hidden;
  white-space: break-spaces;
  text-overflow: ellipsis;
  height: 45px;
}

.subcategory{
  margin-top: 11px;
}

.selectRow {
  width: 670px;

}
.selectCategory{
  margin-top: 50px;
}
.sortby{
  margin-top: 10px;
  margin-left: 40px;
}
.select{
width: 100px;
height: 80px;
}
.selectedSubCategoryName{
  font-size: 20px;
  font-weight:600;
  margin-top: 11px;
}
.medicinePacking{
color:#438d8f;
}
.caption.medicineinfo{
  width: 500px;
}
.product{
  /* height: 300px !important; */
  background-color:#fff;
}
.selectedSubCateByProducts{
  background-color: #fff;
}
.mainDivSubCatProduct{
  background-color: #fff;
}
header {
  background: #274888;
  color: #fff;
}
header .top-header {
  border-bottom: 1px solid #24aeb1;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  background: #24aeb1;
}

header .top-header ul {
  display: flex;
  list-style: none;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
}

header .top-header ul li a span {
  font-size: 0;
}

header .top-header ul li a i {
  font-size: 15px;
  color: #fff;
  font-family: 'FontAwesome';
  font-weight: 100;
}

header .top-header ul li {
  padding-right: 10px;
}

header .top-header p {
  margin: 0;
}

header .main-header {
  padding: 15px 0;
  position: fixed;
  width: 100%;
  background: #ffffff;
  top: 45px;
  transition: .5s all ease-in-out;
  -webkit-transition: .5s all ease-in-out;
}
header .main-header.sticky {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  box-shadow: 0 0 20px 0 rgb(21 47 95 / 20%);
  transition: .5s all ease-in-out;
  -webkit-transition: .5s all ease-in-out;
}
header .main-header .col-lg-2 img {margin-top: 15px;}


/* // myorders// */
.upload-prescription .Prescription-new-upload {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}
.upload-prescription .Prescription-new-upload h1, .upload-prescription .Prescription-new-upload h2 {
  font-size: 20px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 15px;
}
.upload-prescription .Prescription-new-upload h3 {
  font-size: 14px;
  color: #757575;
  margin-bottom: 20px;
}
.upload-prescription .Prescription-new-upload .button-text {
  display: flex;
  color: #757575;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 10px;
  border: 1px solid #e0e0e0;
  padding: 0;
  background-color: #f6f6f6;
  max-width: 340px;
}
.upload-prescription .Prescription-new-upload .button-text img {
  height: 40px;
  width: 40px;
  margin: 16px 27px;
}
.upload-prescription .Prescription-new-upload .button-text .box-text {
  display: block;
  background-color: #fff;
  width: 100%;
  padding: 27px 17px;
  color: #424242;
  font-size: 14px;
  font-weight: 700;
  margin: 1px;
}
/*.Prescription-new-upload-button {
  background-color: rgb(199, 199, 199);
  color: rgb(255, 255, 255);
  height: 40px;
  padding: 0px 16px;
  display: inline-flex;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  border-radius: 4px;
  z-index: 100;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}*/
.Prescription-new-upload-button, .book-consult-link {
  font-size: 13px;
  margin-right: auto;
  align-items: center;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  width: auto;
  height: auto;
  display: flex;
  padding: 0 20px 0 0;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  background-image: linear-gradient(to right,#24aeb1 0,#24aeb1 100%);
  position: relative;
  z-index: 1;
  border-radius: 30px;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  -moz-transition: all .3s;
  -webkit-transition: all .3s;
  transition: all .3s;
  background-repeat: no-repeat;
  background-size: 0 100%;
  color: #1d2a38;
}
.Prescription-new-upload-button:hover, .book-consult-link:hover {
  color: #fff;
  background-size: 100% 100%;
}
.Prescription-new-upload-button:before, .book-consult-link:before {
  content: '\f30b';
  width: 40px;
  height: 40px;
  border-radius: 40px;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  -moz-transition: all .3s;
  -webkit-transition: all .3s;
  transition: all .3s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 5px;
  z-index: 1;
  background-color: #24aeb1;
  font-family: 'FontAwesome';
  font-weight: normal;
}
.Prescription-new-upload-button:hover:before, .book-consult-link:hover:before {
  text-indent: 15px;
}
.upload-prescription-attached {
  margin: 40px 0;
  border-top: 1px solid #dddd;
  padding-top: 10px;
}
.upload-prescription-attached h4 {
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #212121;
  margin: 20px auto;
}
.upload-prescription-attached .upload-prescription-inner {
  display: flex;
}
.upload-prescription-attached .upload-prescription-inner .upload-prescription-bg {
  width: 70px;
  height: 70px;
  border-radius: 4px;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-prescription-attached .upload-prescription-inner h5 {
  width: 175px;
  height: 38px;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #757575;
  margin-left: 20px;
  margin-top: 16px;
}
.upload-prescription .Prescription-new-upload ul.Prescription-list {
  margin-top: 20px;
  padding-left: 18px;
  font-size: 15px;
  margin-left: 20px;
}
.upload-prescription .Prescription-new-upload ul.Prescription-list li {
  padding-bottom: 12px;
  list-style: disc;
}
.regulations-prescription {
  font-size: 14px;
  margin-top: 10px;
}
.regulations-prescription sup {
  color: red;
}

/* Order Medicine */
.order-medicine {
  padding: 60px 0 30px;
}
.order-medicine .order-medicine-bg {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}
.order-medicine-detl {
  background: #24aeb1;
  padding: 20px 20px 30px 20px;
  border-radius: 8px;
  margin-top: 30px;
}
.order-medicine-detl ul {
  display: flex;
  list-style: none;
  margin-bottom: 25px;
}
.order-medicine-detl ul li {
  margin-right: 30px;
  color: #fff;
  font-size: 14px;
  line-height: 30px;
}
.order-medicine-detl ul li img {
  width: 25px;
  margin-right: 8px;
}
.order-medicine-cta {
  margin-top: 30px;
}
.order-medicine-cta button {
  background: #f1f1f1;
  border: none;
  width: 100%;
  margin-bottom: 25px;
  border-radius: 10px;
  text-align: left;
  padding: 12px 20px;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  box-shadow: 0px 0px 10px 0 rgb(0 0 0 / 25%);
}
.order-medicine-cta button img {
  width: 35px;
  margin-right: 15px;
}
.order-medicine-cta button:before {
  content: '\f105';
  position: absolute;
  right: 15px;
  top: 28%;
  width: 15px;
  height: 15px;
  font-family: 'FontAwesome'!important;
  color: #24aeb1;
  font-size: 18px;
}
.order-medicine-cta button.call-now {
  background: #eb3a7b;
  color: #fff;
}
.order-medicine-cta button.call-now:before {color: #fff;}
.order-via-prescription button {
  background: #f1f1f1;
  border: none;
  width: 40%;
  margin-bottom: 25px;
  border-radius: 10px;
  text-align: left;
  padding: 12px 20px;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  box-shadow: 0px 0px 10px 0 rgb(0 0 0 / 25%);
}
.order-via-prescription button img {
  width: 35px;
  margin-right: 15px;
}
.order-via-prescription button:before {
  content: '\f105';
  position: absolute;
  right: 15px;
  top: 28%;
  width: 15px;
  height: 15px;
  font-family: 'FontAwesome'!important;
  color: #24aeb1;
  font-size: 18px;
}
.order-via-prescription h3 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 20px;
}

.order-via-prescription .order-via-prescription-content {
  border: 1.5px solid #fff;
  padding: 20px;
  color: #fff;
  border-radius: 6px;
  min-height: 130px;
}

.order-via-prescription .order-via-prescription-content h4 {
  font-size: 16px;
  margin-top: 12px;
  line-height: 22px;
}
.order-medicine-cta-details {
  background: #f2f3f5;
  padding: 20px;
  border-radius: 10px;
  height: 100%;
}

.order-medicine-cta-details h3 {
  font-size: 30px;
  margin-bottom: 15px;
  line-height: 38px;
}

.order-medicine-cta-details ul {
  padding-left: 18px;
  margin-bottom: 30px;
  font-size: 16px;
}


#all-medicine-offers {
  margin-top: 30px;
}
#all-medicine-offers .all-medicine-offers-content {
  position: relative;
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  padding: 40px 20px 20px 20px;
  margin-top: 35px;
}
#all-medicine-offers .all-medicine-offers-content .all-medicine-offers-img {
  position: absolute;
  width: 70px;
  height: 70px;
  top: -36px;
  left: 20px;
  background: #fff;
  padding: 10px;
}
#all-medicine-offers .all-medicine-offers-content h4 {
  font-size: 16px;
  margin-bottom: 10px;
}
#all-medicine-offers .all-medicine-offers-content h5 {
  font-size: 14px;
}
#all-medicine-offers.owl-carousel .owl-nav {
  position: absolute;
  top: -80px;
  right: 0;
  display: block !important;
}
#all-medicine-offers.owl-carousel .owl-nav button {
  background: #f2f3f5;
  width: 40px;
  height: 40px;
  line-height: 38px;
  font-size: 26px;
  font-weight: 600;
  border-radius: 50%;
}
#all-medicine-offers.owl-carousel .owl-nav button:hover {background: #24aeb1;}
#all-main-offers .owl-dots {
  display: block;
}
/* All Medicine End */

/* Video Consult */
.video-consult-banner {
  padding: 0;
  position: relative;
}
.video-consult-banner .video-consult-content {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  width: 100%;
  color: #fff;
}
.video-consult-banner .video-consult-content h2 {
  font-size: 34px;
  line-height: 46px;
  margin-bottom: 15px;
}
.video-consult-banner .video-consult-content h2 span {
  display: block;
}
.video-consult-banner .video-consult-content h3 {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 20px;
}
.video-consult-banner .video-consult-content p.avialabel-doctor {
  position: relative;
  margin-bottom: 30px;
}
.video-consult-banner .video-consult-content p.avialabel-doctor img {
  width: 15%;
  margin-right: 15px;
}
.video-consult-banner .video-consult-content p.avialabel-doctor:before {
  content: '';
  position: absolute;
  top: 14px;
  left: 300px;
  width: 10px;
  height: 10px;
  background: #35a0da;
  border-radius: 50%;
}
.video-consult-banner .video-consult-content .Prescription-new-upload-button {
  color: #fff;
  width: 30%;
  margin-bottom: 35px;
  border: none;
}
/*.video-consult-banner .video-consult-content .Prescription-new-upload-button:hover {
  background: linear-gradient(to right,#24aeb1 0,#24aeb1 100%);
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}*/
.video-consult-banner .video-consult-content ul {
  display: flex;
  list-style: none;
  margin-bottom: 25px;
}
.video-consult-banner .video-consult-content ul li {
  margin-right: 30px;
  color: #fff;
  font-size: 14px;
  line-height: 30px;
}
.video-consult-banner .video-consult-content ul li img {
  width: 25px;
  margin-right: 8px;
}
.feature-brand.book-consult {
  padding-top: 60px;
}
.feature-brand.book-consult .owl-carousel .owl-nav {display: block;}
.book-consult .book-consult-content {
  background: #f7f7f7;
  text-align: center;
}
.book-consult .book-consult-content img {
  width: 70%;
  margin: 0 auto 20px;
}
.book-consult .book-consult-content h3 {
  font-size: 18px;
  margin-bottom: 15px;
}
.book-consult .book-consult-content button {
  background: #35a0da;
  font-size: 14px;
  padding: 5px 15px;
  display: block;
  color: #fff;
  border-radius: 30px;
  width: auto;
  border: none;
  margin: 0 auto;
}
.book-consult .book-consult-content:hover button {background: #eb3a7b;color: #fff;}
.feature-brand.book-consult .owl-carousel .item {
  padding: 20px;
  background: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 22%);
  margin: 8px 6px;
}
.book-consult-link {padding: 0px;font-size: 12px;}
.book-consult-link:before {
  width: 30px;
  height: 30px;
}
.book-consult .book-consult-content:hover .book-consult-link {background-size: 100% 100%;color: #fff;}

.order-history {
  border-left: 1px solid #ddd;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}
.order-history p.order-history-status {
  font-size: 15px;
  font-weight: 600;
  position: relative;
  padding-left: 25px;
}
.order-history p.order-history-status span {
  font-weight: normal;
}
.order-history p.order-date, .order-history p.delivery-date {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.order-history p.order-date span, .order-history p.delivery-date span {
  font-weight: normal;
}

.order-history p.order-history-status:before {
  content: '';
  animation: 1s blink ease infinite;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: gray;
  position: absolute;
  top: 5px;
  left: 0;
}
.order-history p.order-history-status.status-approved:before {background-color: green;}
.order-history p.order-history-status.status-pending:before {background-color: darkorange;}
.order-history p.order-history-status.status-rejected:before {background-color: red;}

@-webkit-keyframes blink {
  from, to {
      opacity: 0;
      
  }
  50% {
      opacity: 1;
  }
}


/* Lab Test 31-08-2022 */
.order-via-prescription button:hover {
  background: #eb3a7b;
  color: #fff;
}
.order-medicine-cta button.call-now:hover {
  background: #24aeb1;
}
.leb-test {
  padding: 0;
}
.leb-test-cta-bg {
  background: #fff;
  padding: 20px;
  padding-top: 40px;
  border-radius: 10px;
}
.leb-test-cta-bg .call-action button {
  width: 22.8%;
  margin: 0 1%;
  background: #f1f1f1;
  border: none;
  margin-bottom: 25px;
  border-radius: 10px;
  text-align: left;
  padding: 12px 20px;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  box-shadow: 0px 0px 10px 0 rgb(0 0 0 / 25%);
}
.leb-test-cta-bg .call-action button img {
  width: 35px;
  margin-right: 15px;
}
.leb-test-cta-bg .call-action button:before {
  content: '\f105';
  position: absolute;
  right: 15px;
  top: 28%;
  width: 15px;
  height: 15px;
  font-family: 'FontAwesome'!important;
  color: #24aeb1;
  font-size: 18px;
}
.leb-test-cta-bg .call-action button.call-now {
  background: #eb3a7b;
  color: #fff;
}
.leb-test-cta-bg .call-action button:hover {
  background: #eb3a7b;
  color: #fff;
}
.leb-test-cta-bg .call-action button.call-now:hover {
  background: #24aeb1;
}
.leb-test-cta-bg .call-action button:hover:before, .leb-test-cta-bg .call-action button.call-now:before {color: #fff;}
#price-usp .item img {
  width: 32%;
  margin-bottom: 15px;
}
#price-usp .item h4 {
  font-size: 22px;
  margin-bottom: 6px;
}
#price-usp .item {
  border: 1px solid #f2f3f5;
  padding: 20px;
  border-radius: 10px;
}
.trust-area img {
  width: 28%;
  margin-bottom: 15px;
}
.trust-area  h4 {
  font-size: 19px;
  margin-bottom: 10px;
}
.trust-area p {
  font-size: 14px;
}

#price-usp .owl-dots {
  display: none;
}
#price-usp .owl-nav {
  position: absolute;
  top: 40%;
  right: 0;
  display: block !important;
  width: 100%;
}

#price-usp .owl-nav button.owl-prev {
  background: #f2f3f5;
  width: 40px;
  height: 40px;
  line-height: 38px;
  font-size: 26px;
  font-weight: 600;
  border-radius: 50%;
  position: absolute;
  left: -18px;
}

#price-usp .owl-nav button.owl-next {
  background: #f2f3f5;
  width: 40px;
  height: 40px;
  line-height: 38px;
  font-size: 26px;
  font-weight: 600;
  border-radius: 50%;
  position: absolute;
  right: -18px;
}
#price-usp .owl-nav button:hover {
  background: #24aeb1;
}
.lab-test-cards .leb-test-cta-bg {
  padding-top: 20px;
}
.lab-test-cards .leb-test-cta-bg .lab-test-cards-inner {
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.lab-test-cards .leb-test-cta-bg .lab-test-cards-inner h4 {
  font-size: 20px;
  margin-bottom: 10px;
}
.lab-test-cards .leb-test-cta-bg .lab-test-cards-inner button {
  background: #24aeb1;
  padding: 2px 30px;
  height: 40px;
  border-radius: 25px;
  border: none;
  color: #fff;
}
.lab-test-cards .leb-test-cta-bg .lab-test-cards-inner button:hover {background: #eb3a7b;}
.lab-test-cards .leb-test-cta-bg .lab-test-cards-inner .item-prices strong {
  color: #eb3a7b;
}
.checkout-form form select {
  color: #707070;
  font-size: 14px;
}
.checkout-form form select:focus, .checkout-form form input:focus {outline: none;}

.lab-test-cards .leb-test-cta-bg .surgeries-inner {
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 30px;
  text-align: center;
}

.lab-test-cards .leb-test-cta-bg .surgeries-inner img {
  width: 70%;
  margin-bottom: 20px;
}

.lab-test-cards .leb-test-cta-bg .surgeries-inner h4 {
  font-size: 20px;
}

.lab-test-cards .leb-test-cta-bg .surgeries-inner:hover {
  border: 1px solid #24aeb1;
}
.find-doctor .checkout-form {
  padding: 0px 20px 20px;
}
#exampleModalCenter .modal-dialog {
  max-width: 700px;
}
.order-history .item-prices {
  margin-top: 15px;
}
.order-address h3 {
  font-size: 20px;
}
.order-address p, .order-address span {
  font-size: 14px;
  margin: 6px 0;
}
.sidebar_widget ul.filters__list {
  list-style: none;
}
.sidebar_widget ul.filters__list li {
  color: #232323;
  font-size: 15px;
}
.sidebar_widget ul.filters__list li input {
  margin-right: 10px;
}
.product-block2{
  height: 300px !important;
}
.pathology{
  height: 407px;
  gap: 20px;
}
.radiology{
  gap: 20px;
  height: 500px;
  margin-top: -20px;
}
.caption2{
  height: 300px;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;

}
.caption .posted_in {
  font-size: 13px;
}
.caption .posted_in a {
  color: #7d879c;
  font-family: "Poppins", sans-serif;
}
.caption .posted_in a:hover {
  color: #1c8687;
}

.caption .product__title {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  margin: 0 0 5px;
  padding-top: 5px;
  text-transform: capitalize;
  cursor: pointer;
}
.caption2 .product__title a {
  color: #1d2a38;
}
.caption2 .price {
  font-size: 16px;
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
}
.caption2 .price del,
.caption2 .price del {
  text-decoration: line-through;
  position: relative;
}
.caption2 .price ins,
.caption2 .price ins {
  font-weight: 700;
  font-size: 18px;
  color: #eb3a7b;
  text-decoration: none;
}
.product-block2 {
  position: relative;
  background-color: #fff;
  transition: all ease-out 0.3s;
  display: flex;
  border-radius: 6px;
  flex-direction: column;
  margin-bottom: 0;
  height: 100%;
  overflow: hidden;
  width: 226px;
  height: 220px;
}
.product-block2:hover {
  box-shadow: 0 3px 13px rgb(0 0 0 / 11%);
}
.amount2{
 margin: 8px;
}
.caption2 i{

  color: rgba(214, 126, 12, 0.877) !important;
  margin: 10px;
}